.media-exposure-container {
  padding: 1rem;
  .container{
    max-width: 100%;
    padding: 0;
    img{
      max-height: 300px;
      width: auto;
      max-width: 100%;
    }
    h3{
      font-size: 18px;
      color: initial;
    }
    .credit-text{
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 767px){
  .media-exposure-container {
    .container{
      h3{
        margin-top: 15px;
        text-align: center;
      }
      .credit-text{
        margin-top: 20px;
        text-align: center;
      }
      .justify-center-m{
        justify-content: center;
      }
    }
  }
}
@media only screen and (min-width: 1024px){
  .media-exposure-container {
    grid-template-columns: repeat(5, 1fr);
    max-width: 1280px;
    margin: 3rem auto;
  }
}
//General Settings

.mb-50 {
  margin-bottom:50px;
}