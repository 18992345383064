$color1: #ff1772;
$color2: #7400d2;
$color3: #d5006a;
$color4: #FE6400;


$g: (
        sunset: (
                from: #ff4437,
                to: #241490
        ),
        sunset2: (
                from: #d5006a,
                to: #FE6400
        ),
        underwater: (
                from: #1ddd77,
                to: #1c6fe3
        ),
        night: (
                from: #24032b,
                to: #34789d
        )
);

@function gradient($gradient, $key) {
  @return map-get(map-get($g, $gradient), $key);
}

section {
  width: 50em;
  margin: 2em auto;
  padding: 2em;
  background: #e9e9e9;
  text-align: center;
  position: relative;

  &:before {
    content: attr(class);
    position: absolute;
    top: 1em;
    left: 1em;
    text-transform: uppercase;
    font-weight: bold;
    color: #ccc;
  }
}

.button-login {
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 12px;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
  padding: 10px 20px;

}
.btn-log{
  color: #d5006a;
  border:2px solid #d5006a;
  background:#fff;
  //padding: 7px 15px;
  padding: 10px 24px;
  width: 95px;
  &:not([href]){
    color: #d5006a;
    &:hover{
      color: #d5006a;
    }
  }
}
.btn-log-active{
  color: #fff;
  border:none;
  background-color: #d5006a;
  padding: 12px 28px;
  &:not([href]){
    color: #fff;
    &:hover{
      color: #fff;
    }
  }
}
.owl-nav{
  position: relative;
  top: -40px;
  .owl-prev{
    font-size: 50px !important;
    position: relative;
    left: -15%;
  }
  .owl-next{
    font-size: 50px !important;
    position: relative;
    right: -15%;
  }
}
.owl-theme .owl-nav [class*='owl-']:focus{
  outline: none !important;
}
.owl-theme .owl-nav [class*='owl-']:hover{
  background: none !important;
  color: initial !important;
}


.forgot-pass{
  font-size: 10px;
  text-decoration: underline;
  color: #7400d2;
  font-weight: 700;
}

.btn-back-home{
  color: #d5006a;
  border:2px solid #d5006a;
  background:#fff;
  padding: 7px 15px;
  width: 100%;
  &:not([href]){
    color: #d5006a;

  }
  &:hover{
    color: #d5006a;
    text-decoration: none;
  }
}

.close-game,.expand-game {
  color: #fff;
  z-index: 1;
  //position: absolute;
  //top: 5px;
  //background-color: #bbb;
  //right: -24px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  background: linear-gradient(90deg, #d5006a, #FE6400);
}
