.mysti-chat-container{
    display: flex!important;
    flex-direction: row;
    max-width: 816px;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.bubble-container-pink{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bubble-pink{
    position: relative;
    background-color: #FCEAEF;
    border-radius: 8px;
    display: flex!important;
    justify-content: center;
    align-items: center;
    max-width: 86.5%;
    margin-top: 32px;
}

.bubble-mysti-icon{
    margin-right: 25px;
    align-self: baseline;
    width: 10%;
}

.bubble-pink-tail {
    position: absolute;
    left: -15px;
    top: 20%;
}

.bubble-text{
   padding: 24px;
   margin: 0;
   font-size: 18px;
}

.jack-chat-container{
    display: flex!important;
    flex-direction: row;
    max-width: 816px;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.bubble-container-purple{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.bubble-purple{
    position: relative;
    background-color: #EEE6F5;
    border-radius: 8px;
    display: flex!important;
    justify-content: center;
    align-items: center;
    max-width: 86.5%;
    margin-top: 32px;
}

.bubble-jack-icon{
    margin-left: 25px;
    align-self: baseline;
    width: 10%;
}

.bubble-purple-tail {
    position: absolute;
    right: -15px;
    top: 20%;
}

@media only screen and (max-width: 768px) {
    .bubble-text{
        padding: 16px;
        font-size: 16px;
     }
  }