.game__card {
  position: relative;
  .game-text{
    color: #d5006a;
    position: absolute;
    background-color: #fff;
    padding: 5px 15px;
    border: 2px solid #d5006a;
    border-radius: 10px;
    top: -12px;
    right: 0;
    z-index: 1;
    &:before{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 7px 0 7px;
      border-color: #fff transparent transparent transparent;
      transform: skewX(-30deg);
      bottom: -13px;
      z-index: 5;
      left: 20px;
    }
    &:after{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 17px 9px 0 9px;
      border-color: #d5006a transparent transparent transparent;
      transform: skewX(-30deg);
      bottom: -17px;
      z-index: 4;
      left: 17px;
    }
  }

  &--image {
    width: 100%;
    border-radius: 10px;
    height: 161px;
  }
  &--image:hover{
    opacity: 0.5;
  }
  &--subtitle {
    text-align: center;
    font-size: 12px;
    padding: 0 10px;
  }
  &--title {
    text-align: center;
    margin-bottom: 5px;
    font-size: 16px;
    color: #212529;
  }

  &--title:hover {
   text-decoration: none;
  }
}

.banner-game {
  cursor: pointer;

  .div-homepage-register {
    display: none;
  }

  img {
    opacity: 1 !important;
  }
  .text-iframe{
    color: #fff;
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    font-style: normal;
    font-weight: 1000;
    text-shadow: -2px 2px 0px #83D9ED;
    width: 100%;
    text-align: center;
  }
}
.banner-game2{
  cursor: pointer;

  .div-homepage-register {
    display: none;
  }
  img {
    opacity: 1 !important;
    height: 244px;
  }
  .text-iframe2{
    position: absolute;
    top: 10px;
    color: #fff;
    font-size: 13.2px;
    text-align: center;
  }
}
.game-blur {
  opacity: 0.4;
}

@media only screen and (max-width: 1200px) {
  .game__card {
    &--image {
      height: 145px;
    }
    .play2.button{
      top:53px;
    }
    .div-homepage-register{
      top: 59px;
    }
  }
  .banner-game2 img {
    height: 220px;
  }
}
@media only screen and (max-width: 1024px) {
  .game__card {
    &--image {
      height: 180px;
    }
    .play2.button{
      top:75px;
    }
    .div-homepage-register{
      top: 81px;
    }
  }
  .banner-game2 img {
    height: 275px;
  }
}

@media only screen and (max-width: 550px) {
  .game__card {
    &--image {
      height: 145px;
    }
    .play2.button{
      top:53px;
    }
    .div-homepage-register{
      top: 59px;
    }
  }
  .banner-game2 {
    .text-iframe2{
      font-size: 11px;
    }
  }
  .banner-game2 img {
    height: 225px;
  }
  .banner-game {
    .text-iframe{
      font-size: 15px;
    }
  }

}
@media only screen and (max-width: 400px) {
  .game__card {
    &--image {
      height: 120px;
    }
    .play2.button{
      top:45px;
    }
    .div-homepage-register{
      top: 51px;
    }
  }
  .banner-game2 img {
    height: 192px;
  }
}

.game-card-link:hover{
  text-decoration: none;
}
