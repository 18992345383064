$color1: #F51048;
$color2: #FE6400;

$g: (
        sunset2: (
                from: $color1,
                to: $color2
        ),
);

@function gradient($gradient, $key) {
  @return map-get(map-get($g, $gradient), $key);
}
.button-register {
  text-align: center;
  letter-spacing: 0.5px;
  padding: 10px 20px;
  font-size: 12px;
  //background: linear-gradient(
  //                90deg,
  //                gradient(#{sunset2}, from),
  //                gradient(#{sunset2}, to)
  //);
  background-color: #d5006a;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  &:not([href]){
    color:#fff;
    &:hover{
      color:#fff;
    }
  }
}

.d-inline-table{
  display: inline-table;
}

.div-homepage-register{
  position: absolute;
  top: 68px;
  width: 100%;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .div-homepage-register {
    top: 48px;
    .button-register{
      padding: 10px 15px;
      font-size: 11px;
    }
  }
}
