.button {
  display: inline-block;
  position: absolute;
  top: 60px;
  right: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  //background: linear-gradient(
  //                25deg,
  //                gradient(#{sunset}, from),
  //                gradient(#{sunset}, to)
  //);
  background-color: #d5006a;
  color: white;
  font-size: 0;
}

.button::before {
  content: "";
  display: block;
  position: absolute;
}

.play1.button::before {
  font-family: 'Webdings';
  font-size: 28px;
  content: '\25B6';
  top: -2px;
  left: 12px;
}

.play2.button::before {
  width: 0;
  height: 0;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 15px solid white;
  top: 10px;
  left: 16px;
}

@media only screen and (max-width: 768px) {
  .button {
    top: 40px;
  }
}