.bubble-chat-homepage{
    background-color: white;
    border-radius: 8px;
    display: flex
}
.bubble-chat-homepage p{
    margin: 0;
    font-size: 18px;
    padding-bottom: 16px;
    padding-top: 16px;
}

.bubble-chat-homepage p:nth-child(1){
    padding-left: 16px;
}
.bubble-chat-homepage p:nth-child(2){
    color: #00B0DA;
}
.bubble-chat-homepage p:nth-child(3){
    padding-right: 16px;
}

@media screen and (max-width: 991px) {
    .bubble-chat-homepage p{
        font-size: 11px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
@media screen and (max-width: 768px) {
    .bubble-chat-homepage{
        width:134%;
    }
}