.modal-iframe{
  margin: 0;
  padding: 0;
  border: 0;
  .modal-content{
    width: auto;
  }
  .modal-header{
    padding: 0;
    border: 0;
  }
  .close:focus{
    outline: none;
  }
  .close{
    position: absolute;
    right: 0;
    top: -28px;
    color: #fff;
    opacity: 1;
    font-weight: 100;
  }
  iframe{
    margin: 0;
    padding: 0;
    border: 0;
  }
}

@media only screen and (max-width: 768px) {
  .modal-iframe{
    .close{
      left: -5px;
      right: auto;
      top: -34px;
    }
    border: 0;
    width: 100vw;
    padding-top:50px;
    .modal-dialog{
      height: calc(100vh - 50px);
      border: 0;
      width: 100vw;
      margin: 0;
    }
    .modal-content{
      height: calc(100vh - 50px);
      border: 0;
      width: 100vw;
    }
    iframe{
      height: calc(100vh - 50px);
      border: 0;
      width: 100vw;
    }
  }
}
