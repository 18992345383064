//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
    font-family: 'Noto Sans JP', sans-serif;
}

input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.term-paragraph {
  font-size: 14px;
}

.plt-capacitor.plt-ios {
  margin-top: 40px;
}

.plt-capacitor.plt-ios .mobile{
  display: none;
}

.plt-capacitor.plt-android .mobile{
  display: none;
}
