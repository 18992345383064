.htp-title{
    font-size: 48px;
    margin: auto;
    margin-bottom: 45px;
    max-width: 816px;
}

.htp-subtitle{
    font-size: 28px;
    font-weight: 700;
    margin: auto;
    margin-bottom: 24px;
    max-width: 816px;
}

.htp-bubble-mysti{
    margin-bottom: 106px;
}

.htp-paragraph{
    font-size: 18px;
    max-width: 816px;
    margin: auto;
}

.htp-container{
    cursor: pointer;
    position: relative;
    text-align: center;
}

.htp-image{
    width: 100%;
    max-width: 816px;
    object-fit: contain;
}
.htp-iframe{
    width: 100%;
    max-width: 816px;
    max-height: 459px;
}
.htp-paragraph-card{
    margin-bottom: 32px;
}

.htp-card-description{
    font-size: 18px;
    max-width: 816px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 32px;
}

@media only screen and (max-width: 768px) {
    .htp-title{
        font-size: 26px;
        margin-bottom: 34px;
    }
    .htp-bubble-mysti{
        margin-bottom: 48px;
    }
    .htp-subtitle{
        font-size: 23px;
    }
    .htp-paragraph{
        font-size: 16px;
    }
    .htp-iframe{
        max-height: 282.38px;
    }
}

@media only screen and (max-width: 450px) {
    .htp-iframe{
        max-height: 201.38px;
    }
}