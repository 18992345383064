#root {
  background-color: #FBFBFA;
}

.homepage__container {
  background-color: #FBFBFA;
}

.bg-img-homepage {
  background: url('../imgs/Mystino-bg-tr 1.png');
  background-position: right bottom;
  height: 340px;
  background-color: #E6F7FB;
  background-size: 1200px;
  background-repeat: no-repeat;
}
.home-container-cards{
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 33px;
}
.bg-home-banner-title{
  text-align: center;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  padding-top: 12px;
}
.font26{
  font-size: 26px!important;
}
.css-y2p4sa{
  height: min(12vh, 33em);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.bg-home-banner{
  background-color: #FCEAEF;
  position: relative;
  height: 296px;
  justify-content: center;
  align-items: center;
}
.home-banner-container{
  background-color: #FFFFFF;
  border-radius: 8px;
  padding-top: 18px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}
.button-home-banner{
  background-color: #E32862;
  border-radius: 10px;
  width: 55%;
  margin: auto;
  text-decoration: none;
}
.button-home-banner p{
  color: white;
  text-align: center;
}

.home-banner-container a:hover{
  text-decoration: none;
}
.img-home-banner{
  width: 48px;
  height: 48px;
  margin-bottom: 13px;
}
.scris-home-banner{
  margin-bottom: 11px;
  font-weight: 700;
  font-size: 16px;
}

.img-home-card{
  position: absolute;
  bottom: 0;
  left: 5%;
}
.img-home-ruby{
  position: absolute;
  bottom: 0;
  left: 18%;
}
.img-home-horseShoe{
  position: absolute;
  bottom: 15%;
  left: 30%;
}
.img-home-dice{
  position: absolute;
  bottom: 12%;
  right: 25%;
}
.img-home-7{
  position: absolute;
  bottom: 0;
  right: 5%;
}

.banner-container {
  height: 340px;
  position: relative;

  img {
    bottom: 0;
    position: absolute;
  }

  .text-banner {
    top: 33px;
    right: 166px;
  }

  p {
    span{
      background-color: #fff;
      border-radius: 8px;
      width: max-content;
      padding: 16px;
      font-size: 18px;
      line-height: 30px;
      color: #1C273D;
    }
  }

  .first-text {
    position: absolute;
    top: 45px;
    left: 179px;
    img{
      top: 10px;
      left: -20px;
    }
    .hidden{
      display: none;
    }
  }

  .second-text {
    position: absolute;
    top: 143px;
    right: 40px;
    img{
      top: 10px;
      right: -20px;
    }
    .hidden{
      display: none;
    }
  }

  .last-text {
    position: absolute;
    left: 179px;
    bottom: 53px;
    img{
      top: 10px;
      left: -20px;
    }
  }
}

.gads-left-right {
  margin-left: 10px;
  margin-right: 10px;
  //border-color: red;
  //border-style: solid;
  //border-width: 5px;
  width: 160px;
  height: 600px;
  position: absolute;
  top: 0;
  z-index: 1;

}
footer{
  .home-text-container{
    .font-size-m p,
    .font-size-m a,
    .font-size-m div{
      font-size: 12px;
      line-height: 18px;
    }
  }

}

.gameproducers__container {
  margin-top: 5%;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .owl-stage{
    display: flex;
    align-items: center;
    justify-content: center;

    .owl-item{
      height: 64px;
      width: 121px;
    }
  }
  &--title {
    padding: 1rem 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .gameproducer__container{
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 5px;
    width: 121px;
    height: 64px;
    .gameproducer__box--image {
      width: 120px;
      height: 64px;
    }
    .gameproducer__box--image img{
      width: 100%;
      height: auto;
      max-height: 64px;
      max-width: fit-content;
    }
  }
}

.game__container {
  display: grid;
  padding: 1rem;
  grid-auto-columns: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.gameplay__container {
  background: url('../imgs/game_background.jpg');
  background-position: center;
  background-size: cover;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;

  .iframe-game {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  h1 {
    text-align: center;
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .game-description {
    text-align: center;
    margin-bottom: 0;
  }

  .container {
    max-width: 1280px;
    padding-left: 0;
    padding-right: 0;
  }
}

.slideshow__container {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-family: 'Gothic A1', sans-serif;
  //background: url('../imgs/header_homepage.jpg');
  height: 450px;

  &--button {
    text-align: center;
    font-weight: bold;
    background-color: yellow;
    color: black;
    padding: 10px 30px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 30px;

    &:hover {
      text-decoration: none;
    }
  }

  .bg-img-home-right {
    background: url('../imgs/MystinoJP_right.jpg');
    display: flex;
    align-items: center;
  }

  .bg-img-home-right,
  .bg-img-home {
    background-size: cover;
    background-position: center;
    text-align: center;
    background-repeat: no-repeat;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  .button-register {
    //position: absolute;
    //bottom: 3%;
    font-size: 22px;
    padding: 10px 30px;
    border-radius: 5px;
    color: #000 !important;
    background-image: none;
    background-color: #c8aa3a;
    display: inline-block;
    //margin-left: 40px;
    margin-top: 10px;
    width: 100%;
    max-width: 500px;
  }

  h1 {
    font-size: 60px;
    color: #fff;
    font-weight: 1000;
    //width: 50%;
    //transform: translateX(10%);
    width: fit-content;
    text-align: center;
    //margin-left: 40px;
  }

  .row-header {
    //width: 60%;
    //transform: translateX(10%);
    //max-width: 500px;
    //margin-left: 40px;
    .col-md-4 {
      margin-top: 30px;
      display: flex;
      padding: 0;

      p {
        font-size: 25px;
        color: #fff;
        font-weight: 700;
      }

      img {
        width: 42px;
        height: 85px;
      }
    }
  }
}

.ml-50 {
  margin-left: 50px;
}

.chest__container {
  display: flex;
}

.chest-image {
  width: 100%;
}

.home-text h1 {
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  margin-bottom: 25px;
}

.home-text p {
  font-size: 15px;
}

.iframe-game.toggle-full.active {
  position: inherit;

  iframe {
    height: calc(100vh);
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    position: absolute;
    top: -70px;
  }

  .close-game {
    z-index: 3;
    right: 7px;
    top: -20px;
  }

  .expand-game {
    z-index: 3;
    top: 35px;
    bottom: auto;
    right: 7px;
  }
}

.btns-wrapper {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;

  div {
    margin-right: 10px;
  }
}

.info-game {
  padding: 0 10px;
}
.img-footer{
  border-bottom: 2px solid #C9CBCF;
}
.img-jack-mobile{
  display: none;
}
.cursor-pointer{
  cursor: pointer;
}
.color-pink{
  color: #E32862!important;
}
@media only screen and (max-width: 1350px) {
  .img-home-card{
    left: -2%;
  }
  .img-home-ruby{
    left: 13%;
  }
  .img-home-horseShoe{
    left: 25%;
  }
  .img-home-dice{
    right: 22%;
  }
  .img-home-7{
    right: 0%;
  }
}

@media only screen and (max-width: 1200px) {
  .banner-container .text-banner{
    right: 150px;
    width: 150px;
  }
}

@media only screen and (max-width: 1085px) {
  .bg-home-banner{
    height: 390px;
  }
  .img-home-card{
    left: -3%;
    width: 27vw;
  }
  .img-home-ruby{
    left: 20%;
    width: 25vw;
  }
  .img-home-horseShoe{
    left: 37%;
    width: 10vw;
    min-width: 58px;
  }
  .img-home-dice{
    right: 30%;
    width: 13vw;
    bottom: 8%;
    max-width: 100px;
  }
  .img-home-7{
    right: 2%;
    width: 30vw;
    max-width: 250px;
  }
}

@media only screen and (max-width: 991px) {
  .bg-img-homepage {
    height: 380px;
  }
  .img-jack-mobile{
    display: block;
  }
  .img-jack{
    display: none;
  }

  .banner-container {
    .hidden-mobile {
      display: none;
    }
    max-width: 100%;
    height: 380px;
    overflow-x: clip;
    .text-banner{
      top: 40px;
      right: 65px;
      width: auto;
    }
    .img-mysti{
      left: -80px;
      width: auto;
      max-height: 500px;
    }
    .img-jack{
      max-height: 300px;
      width: auto;
      right: -30px;
      overflow: hidden;
    }
    p span{
      font-size: 11px;
      display: block;
      max-width: 220px;
      line-height: 18px;
      padding: 8px 16px;
    }
    .first-text{
      .hidden{
        display: block;
        top: 8px;
      }
      top: 100px;
      left: 85px;
      img{
        top: 32px;
      }
    }
    .second-text{
      top: 180px;
      right: -70px;
      max-width: 176px;
      img{
        display: none;
      }
      .hidden{
        display: block;
        position: absolute;
        top: 50px;
        right: 25px;
      }
    }
    .last-text{
      bottom: 65px;
      left: 82px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .banner-container {
    .text-banner{
      width: 170px;
      right: 50px;
    }
    .hidden-mobile{
      display: none;
    }
    .first-text{
      top: 106px;
      left: 130px;
      max-width: 209px;
      img{
        top: 9px;
      }
    }
    .second-text{
      top: 170px;
      right: 50px;
      max-width: 176px;
      img{
        display: none;
      }
      .hidden{
        display: block;
        position: absolute;
        top: 50px;
        right: 25px;
      }
    }
    .last-text{
      bottom: 77px;
      left: 138px;
      max-width: 134px;
    }
    p span{
      padding: 8px 16px;
      font-size: 11px;
      line-height: 18px;
      width: 100%;
    }
  }
  .btns-wrapper {
    justify-content: end;
  }
  .slideshow__container {
    //height: 120px;
    &--button {
      padding: 5px 20px;
      font-size: 15px;
      border-radius: 10px;
    }

    .button-register {
      bottom: 1%;
      font-size: 14px;
      padding: 3px 15px;
      border-radius: 30px;
    }
  }
  .gameproducers__container {
    &--title {
      font-size: 1.5rem;
    }
  }
  .gameplay__container {
    iframe {
      width: 370px;
      height: 210px;
    }

    h1 {
      font-size: 20px;
    }

    .game-description {
      font-size: 15px;
    }
  }
  .gameproducers__container {
    margin: 1rem;
  }
  .iframe-game {
    height: calc(55vh - 4px);
  }
  .iframe-game iframe {
    height: calc(100vh - 4px);
    width: calc(100vw - 4px);
    box-sizing: border-box;
  }
  .game__m__container .gameplay__container {
    width: 100%;
    left: 0;
    right: 0;
    padding-top: 0;
  }
  .gameplay__container iframe {
    width: 100%;
    height: 100%;
  }
  .banner-container {

    .img-mysti {
      left: -92px;
      width: auto;
      max-height: 390px;
    }

    .img-jack {
      max-height: 245px;
      width: auto;
      right: -30px;
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 577px) {
  .img-footer{
    background-color: #f1f1f1;
    border-bottom: 2px solid #C9CBCF;
  }
  .bg-mobile{
    background-color: #f1f1f1;
  }
  .img-footer span{
    right: 21px;
    width: 120px;
    font-size: 14px;
  }
  .bg-img-homepage {
    height: 380px;
  }
  //.banner-container {
  //  .first-text{
  //    top: 100px;
  //    left: 170px;
  //    img{
  //      top: 32px;
  //    }
  //  }
  //  .second-text{
  //    top: 178px;
  //    right: 75px;
  //    img{
  //      display: none;
  //    }
  //    .hidden{
  //      display: block;
  //      position: absolute;
  //      top: 56px;
  //      right: 34px;
  //    }
  //  }
  //  .last-text{
  //    bottom: 65px;
  //    left: 158px;
  //  }
  //}
}

@media only screen and (max-width: 500px) {
  //.banner-container {
  //  .first-text{
  //    top: 100px;
  //    left: 135px;
  //  }
  //  .second-text{
  //    top: 178px;
  //    right: 55px;
  //  }
  //  .last-text{
  //    bottom: 65px;
  //    left: 138px;
  //  }
  //}
}

@media only screen and (max-width: 450px) {
  .img-home-horseShoe{
    bottom: 9%;
  }
  .bg-home-banner{
    height: 348px;
  }
}
@media only screen and (max-width: 430px) {
  //.banner-container {
  //  .first-text{
  //    top: 140px;
  //    left: 100px;
  //  }
  //  .second-text{
  //    top: 210px;
  //    right: 45px;
  //  }
  //  .last-text{
  //    bottom: 45px;
  //    left: 96px;
  //  }
  //}
}
@media only screen and (max-width: 400px) {
  //.banner-container .text-banner{
  //  width: 140px;
  //}
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .gameplay__container iframe {
    width: calc(100% - 100px);
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .gameplay__container iframe {
    height: 88%;
  }
  .gameplay__container {
    padding-top: 0;
  }
  .gameplay__container .iframe-game {
    padding-top: 46.25%;
  }
}

@media only screen and (min-width: 768px) {
  .slideshow__container {
    //height: 65vh;
  }

  .game__container {
    grid-template-columns: repeat(3, 1fr);
  }

}

@media screen and (min-width: 1900px) {
  .slideshow__container {
    //min-height: 500px;
  }
}

@media only screen and (min-width: 1024px) {


  .game__container {
    grid-template-columns: repeat(5, 1fr);
    margin: 3rem auto;
    max-width: 1280px;
  }

  .gameproducers__container {
    margin: auto;
    max-width: 1280px;
    margin-bottom: 0;
  }

  .gameplay__container iframe {
    width: 66%;
    height: 90%;
  }
  .home-text-container .home-text {
    max-width: 640px;
    margin-left: auto;
    margin-top: 15px;
  }

  .gameplay__container {
    .iframe-game {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .close-game {
      top: 6px;
      right: 172px;
    }

    .expand-game {
      top: 50px;
      right: 172px;
    }
  }

}

.home-image {
  margin-top: auto;
}

@media screen and (max-width: 1600px) {
  //.gameplay__container iframe {
  //  width: 90%;
  //  height: 90%;
  //}
  .gameplay__container .iframe-game {
    //margin-bottom: -11%;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .slideshow__container {
    //height: 50vh;

    .button-register {
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
      border-radius: 30px;
    }
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .slideshow__container {
    //height: 17vh;

    .button-register {
      bottom: 1%;
    }
  }
}

/* Portrait  6+, 7+ and 8+*/
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .slideshow__container {
    //height: 20vh;

    .button-register {
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
      border-radius: 30px;
    }
  }
}

/* Landscape 6+, 7+ and 8+*/
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .slideshow__container {
    //height: 45vh;

    .button-register {
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
      border-radius: 30px;
    }
  }
}


/* ----------- iPhone X ----------- */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .slideshow__container {
    //height: 20vh;

    .button-register {
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
      border-radius: 30px;
    }
  }
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .slideshow__container {
    //height: 60vh;

    .button-register {
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
      border-radius: 30px;
    }
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  //.slideshow__container {
  //  height: 33vh;
  //}
}

@media only screen and (max-width: 250px) {
  .slideshow__container {
    height: 120px;
  }
}

@media screen and (min-width: 250px) and (max-width: 350px) {
  .slideshow__container {
    height: 140px;
  }

  .gads-mobile {
    width: 100% !important;
  }
}

@media screen and (min-width: 350px) and (max-width: 450px) {
  .slideshow__container {
    height: 160px;
  }
}

@media screen and (min-width: 450px) and (max-width: 550px) {
  .slideshow__container {
    height: 240px;
  }
}


@media screen and (max-width: 767px) {
  .slideshow__container {
    //height: 500px !important;
    .bg-img-home {
      //height: 50%;
    }

    h1 {
      margin-left: 0;
      margin-top: 15px;
      font-size: 30px;
      text-align: center;
      width: auto;
    }

    .bg-img-home-right {
      height: 50%;
    }

    .row-header {
      max-width: 100%;
      width: 100%;
      margin-left: 0;

      .col-md-4 {
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        p {
          font-size: 22px !important;
        }

        img {
          width: 28px;
          height: 58px;
        }
      }
    }

    .button-register {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 100%;
      padding: 10px;
      border-radius: 5px;
    }
  }
}

@media screen and (max-width: 767px) {
  .slideshow__container {
    .bg-img-home-right {
      justify-content: center;

      .ml-50 {
        margin: 0;
      }

      h1 {
        margin: 0;
        font-size: 50px;
        line-height: 50px;
      }

      .row-header {
        .col-md-4 {
          margin: 0;

          p {
            margin: 0;
            font-size: 20px !important;
          }
        }
      }

      .button-register {
        padding: 2px;
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .slideshow__container {
    .bg-img-home-right {
      .ml-50 {
        width: 100%;
      }

      h1 {
        margin-left: 0;
        margin-top: 5px;
        font-size: 28px;
        text-align: center;
        width: auto;
        line-height: initial;
      }

      .bg-img-home-right {
        height: 50%;
      }

      .row-header {
        max-width: 100%;
        width: 100%;
        margin-left: 0;

        .col-md-4 {
          align-items: center;
          justify-content: center;
          margin-top: 0;

          p {
            font-size: 16px !important;
          }

          img {
            width: 32px;
            height: 70px;
          }
        }
      }

      .button-register {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 100%;
        padding: 10px;
        border-radius: 5px;
        font-size: 20px;
      }
    }
  }
  .logo {
    height: 4.4rem;
  }
}

@media screen and (min-width: 700px) and (max-width: 900px) {
  .slideshow__container {
    height: 305px;

    h1 {
      margin-left: 0;
      margin-top: 15px;
      font-size: 30px;
      text-align: center;
      width: auto;
    }

    .row-header {
      margin-left: 0;

      .col-md-4 {
        p {
          font-size: 16px;
        }

        img {
          width: 32px;
          height: 70px;
        }
      }
    }

    .button-register {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .chest-image {
    width: 135%;
  }
  .home-image {
    overflow: hidden;
  }
  .slideshow__container {
    .bg-img-home-right {
      justify-content: center;

      .ml-50 {
        margin-left: 0;
        width: 100%;
      }

      h1 {
        font-size: 40px;
      }

      .row-header {
        .col-md-4 {
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .close-game {
    right: 7px;
  }
  .expand-game {
    right: 7px;
    top: 47px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .slideshow__container {
    height: 390px;

    .bg-img-home-right {
      .ml-50 {
        width: 100%;
        margin-left: 0;
        padding: 0 20px;
      }
    }

    h1 {
      font-size: 50px;
      text-align: center;
      width: auto;
    }

    .row-header {
      .col-md-4 {
        margin-top: 0;

        p {
          font-size: 20px;
        }

        img {
          width: 32px;
          height: 70px;
        }
      }
    }

    .button-register {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 991px) and (max-width: 1350px) {
  .game__container {
    margin: 3rem 15px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1350px) {
  .gameproducers__container--title {
    padding-left: 2rem;
  }
  .footer__desktop {
    padding-left: 2rem;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1900px) {
  .slideshow__container {
    height: 500px;
  }
}

@media screen and (min-width: 1900px) and (max-width: 2400px) {
  .slideshow__container {
    height: 560px;
  }
}

@media screen and (min-width: 2400px) and (max-width: 2900px) {
  .slideshow__container {
    height: 700px;
  }
}

@media screen and (min-width: 2900px) and (max-width: 3500px) {
  .slideshow__container {
    height: 900px;
  }
}

@media screen and (min-width: 3500px) {
  .slideshow__container {
    height: 1000px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .slideshow__container {
    min-height: 720px;
    height: 720px;

    .bg-img-home {
      height: 720px;
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
  .slideshow__container {
    height: 615px;
  }
  .bg-img-home {
    background-position: center !important;
  }

}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .slideshow__container {
    height: 480px;
  }
}

@media only screen and (max-width: 400px) {
  .slideshow__container {
    height: 425px;
  }
  .navbar__container .button-container {
    text-align: center;
    padding: 0;

    div {
      margin-bottom: 10px;
    }
  }
  //.homepage__container {
  //  margin-top: 40px;
  //}
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
  .gameplay__container iframe {
    width: calc(64% - 100px);
  }
  .gads-left-right {
    height: 550px;
  }
  .gameplay__container .iframe-game {
    padding-top: 56%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .gameplay__container iframe {
    width: calc(60% - 100px);
  }
  .gads-left-right {
    height: 400px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1250px) {
  .gameplay__container iframe {
    width: 65%;
    height: 90%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1100px) {
  .gameplay__container iframe {
    width: 60%;
    height: 90%;
  }
  .gameplay__container .iframe-game {
    padding-top: 60.25%;
  }
}

@media screen and (min-width: 768px) {
  .gads-mobile {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .gameproducers__container--title {
    font-size: 16px;
    line-height: 24px;
  }
  .font-size-m{
    font-size: 12px;
    line-height: 16px;
  }
  .gameproducers__container {
    align-items: center;
    justify-content: center;
    .gameproducer__box--image img {
      width: 100%;
      height: auto;
      max-height: 64px;
      max-width: fit-content;
      padding: 10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .gads-mobile {
    display: block;
    //border-color: red;
    //border-style: solid;
    //border-width: 5px;
    width: 320px;
    height: 50px;
    margin: auto;
    margin-bottom: 20px;
  }
  .gads-left-right {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1024px) {
  .gameplay__container .iframe-game {
    padding-top: 61.25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .close-game {
    right: 172px;
  }
  .expand-game {
    right: 172px;
    top: 47px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .slideshow__container {
    height: 390px;
  }
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

//new page - domains

.domains {
  max-width: 670px;
  padding-bottom: 100px;

  .domains__title {
    font-size: 32px;
    font-weight: 700;
    margin-top: 50px;
  }

  .domains__subtitle {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 26px;
  }

  .domain__box.pink__box {
    background-color: #FCEAEF;
    padding: 16px 16px 2px 16px;

    .white-do {
      background-color: #ffffff;
      padding: 0 12px 3px 12px;
      border-radius: 4px;
    }
  }

  .domain__box {
    padding: 0 16px;
    border-radius: 5px;
    margin-bottom: 32px;

    .gray-do {
      background-color: #dfe0e2;
      padding: 0 12px 3px 12px;
      border-radius: 4px;
    }

    span:first-child {
      background: #E32862;
      padding: 3px 10px;
      color: #ffffff;
      border-radius: 50%;
      margin-right: 30px;
    }

    p {
      margin: 12px 55px;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

//new homepage
.pink__band.ho {
  background-color: #BE1A4D;
  text-align: center;
  padding: 10px 0px;
  font-size: 13px;
  color: #ffffff;

  a {
    color: #F9CEDB;
    text-decoration: none;
  }
}

.pink__band.do {
  background-color: #BE1A4D;
  text-align: center;
  padding: 10px 0px;
  font-size: 13px;
  color: #ffffff;

  a {
    color: #F9CEDB;
    text-decoration: none;
  }
}

.box__container {
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;

  .box {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.homepage__subtitle {
  padding: 25px 0;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .game__container,.bg-mobile, .img-footer {
    background-color: #FBFBFA;
  }
  .p-10{
    padding: 10px;
  }
}
@media screen and (max-width: 576px) {
  .pink__band.do {
    display: none
  }
  .pink__band.ho {
    font-size: 11px;
  }
  .homepage__subtitle {
    font-size: 14px;
    font-weight: 700;
  }
  .box .desk-text-box {
    display: none;
  }
  .box .mob-text-box {
    font-size: 11px;
    text-align: center;
  }
  .box svg {
    max-width: 85px;
  }
  .box__container {
    margin-top: -25px;
  }

}

@media screen and (min-width: 576px) {
  .box .mob-text-box {
    display: none;
  }
}
