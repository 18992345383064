.accordion {
  width: 100%;
}

.accordion__item {
  margin: 0 1rem;
}

.accordion__heading {
  width: 100%;
}

.accordion__button {
  color: #444;
  cursor: pointer;
  padding: 18px 4px;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #eee;
  outline: none;
  &::after {
    display: block;
    float: right;
    content: '+';
    height: 10px;
    width: 10px;
    margin-right: 12px;
  }
}

.accordion__button[aria-expanded='true']::after {
  content: '-';
}
