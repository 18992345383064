.rg-title{
    font-size: 48px;
    margin: auto;
    margin-bottom: 45px;
    max-width: 816px;
}

.rg-subtitle{
    font-size: 28px;
    margin-bottom: 24px;
    font-weight: 700;
    margin: auto;
    margin-bottom: 20px;
    max-width: 816px;
}

.rg-bubble-jack{
    margin-bottom: 64px!important;
}

.rg-paragraph{
    font-size: 18px;
    max-width: 816px;
    margin: auto;
}

.link-in-paragraph{
    color: #212529;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .rg-title{
        font-size: 26px;
        margin-bottom: 34px;
    }
    .rg-bubble-jack{
        margin-bottom: 48px;
    }
    .rg-subtitle{
        font-size: 23px;
    }
    .rg-paragraph{
        font-size: 16px;
    }
}