.gameproducer__box {
  &--image {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--title {
    width: 100%;
    text-align: center;
    p {
      padding-top: 5px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .gameproducer__box {
    &--image {
      img {
        padding: 10px 15px;
      }
    }
  }
}
