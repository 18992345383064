.navbar__container {
  width: 100%;
  padding: 0;
  /*max-width: 1280px;*/
  margin: 0 auto;
  /*height: 70px;*/
  z-index: 1;
  button {
    width: 20%;
  }
  .marquee {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    background-color:#000;
    color:#fff;
    p {
      display: inline-block;
      width: max-content;
      margin: 5px 0;
      padding-left: 100%;
      will-change: transform;
      animation: marquee 100s linear infinite;
    }
    span {
      margin:0 50px;
  }

  }
  .media-exposure-btn {
    display:inline-block;
      a {
        color: #d50369;
        padding: 10px 20px;
        font-size: 12px;
        border: 2px solid #d50369;
        border-radius: 10px;
        margin: 15px;

        &:hover {
          text-decoration: none;
        }
      }
    }


  & > * {
    //justify-self: center;
  }
  .user-logged{
    margin-right: 15px;
    display: inline-block;
  }
  .button-register{
    display: inline-block;
    //margin-right: 15px;
  }
  .row{
    margin: 30px 0 10px;
    .col-md-6{
      //padding-right: 0;
      //padding-left: 0;
    }
  }
}
.logo{
  height: 5rem;
}

$bar-width: 30px;
$bar-height: 4px;
$bar-spacing: 10px;
$bar-color: #ccc;

.menu-wrapper {
  width: $bar-width;
  height: $bar-height + $bar-spacing * 2;
  cursor: pointer;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
  height: $bar-height;
  border-radius: 30px;
}

.hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: $bar-color;
  transition: all 0ms 300ms;

  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}

.hamburger-menu:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: $bar-color;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: '';
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: $bar-color;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.search-icon {
  width: 25px;
}
.register-container{
  display: inline-block;
  margin-right: 15px;
  margin-top: 10px;
}
.button-container{
  text-align: right;
}
.btr-m{
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  .main-nav-header {
    height: 88px;
    a.main-logo-wrp {
      img {
        height:90px;
        position: relative;
        top: -10px;
      }
    }
  }
  .domains-container {
    background-color: #FBFBFA;
  }

  .navbar__container {
    height:120px;

    .user-logged {
      display: none;
    }
    .logo-container{
      left: 5%;
    }
    .col-sm-6{
      width: 50%;
    }
    .register-container{
      //position: absolute;
      //left: calc(10px - 100%);
      //margin-top: 0;
    }
    .button-login{
      display: inline-block;
    }
    .hide-mobile {
      display:none;
    }
    .button-container{
      margin-top: 5px;
      .button-register{
        padding: 10px 18px;
        width:95px;
      }
      .button-login{
        width: auto;
        margin-right: -5px;
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .button-container{
    .button-login{
      padding: 8px 20px;
    }
    .btn-log-active{
      height: 38px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  .logo-container{
    padding-top: 4px;
    .logo{
      height: 2.1rem;
    }
  }
}
@keyframes marquee {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}


//NEW NAVBAR
.overlay {
  height: 100%;
  width: 350px;
  transform: translate3d(-100%, 0, 0);
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color:#ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;

  &.active {
    transform: translate3d(0, 0, 0);
  }
}

.menu-backdrop {
  background: #1C273D;
  opacity: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity .3s ease;

  &.active {
    opacity: 0.8;
    pointer-events: auto;
  }

  &.notActive {
  display: none;
  }

  .closebtn {
    position: absolute;
    left: 360px;
    font-size: 24px;
    color: #ffffff;
    &:hover {
      text-decoration: none;
    }
  }
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.item_links.loggedIn {
  display: flex;
  flex-direction: column;
}

.item_links {
  border-top:1px solid #eaeaea;
  border-bottom:1px solid #eaeaea;
  padding-bottom:15px;

    a {
      background-color:transparent;
      border:none;
      color:#1C273D !important;
      text-align:left;
      padding: 20px 20px !important;
      font-size:14px;
    }

  .extra-border {
    border-bottom:1px solid #eaeaea;
    padding-bottom: 20px;
  }
}
.mt-20 {
  margin-top:20px;
}
.props-links a {
  display:block;
  width:100%;
}
.props-links a:first-child {
  border-radius:0;
}
.borderBottom {
  border-bottom: 1px solid #eaeaea;
}

.nav-header {
  padding-top:20px;
  display: flex;
  align-items: center;
  height: 90px;
  position: relative;
  .logo-wrp {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: -15px;
  }
  .bunny {
    position: absolute;
    right: 0;
    top: 15px;
  }

}
.main-nav-header {
  display: flex;
  height:120px;
  padding-top:20px;
  background-color:#ffffff;
  box-shadow: inset 0px -1px 0px #EDEEEF;
  position: relative;
  span {
    margin-left:9px;
    font-size:24px !important;
    cursor:pointer;
    z-index:1;
  }
  .menu-icon{
    display: flex;
    position: relative;
    top: -10px;
    align-items: center;
    z-index: 10;
  }
  .main-logo-wrp {
    display: flex;
    width:100%;
    justify-content: center;
    margin-left: -55px;
    z-index:3;
    //margin-left:-42px;
    img {
      height: 120px;
    }
  }
  .bunny {
    position: absolute;
    top: 3px;
    right: 0;
  }
  span svg {
    margin-left:15px;
  }
}

.overlay h2, .overlay p {
  color:white;
}

.domains-container {
  display:flex;
  justify-content:center;
  align-items:center;
  padding:25px 0 20px 0;
  background-color:#FBFBFA;
  height: 120px;
  p {
    margin:0;
    font-size: 14px;
  }
  .input-domains {
    background-color:#ffffff;
    padding:8px;
    margin-left:5px;
    border:1px solid #C9CBCF;
    border-radius:4px;
    position: relative;
    align-items: center;
    display: flex;

    span {
      font-size: 13px;
    }
    .domain-extension {
      width: 26px;
      height: 20px;
      overflow: hidden;
      background: #EDEEEF;
      border-radius: 5px;
      margin: 0 6px;
     span {
       animation: scrollAnimation 3s linear infinite;
       display: block;
       line-height: 20px;
       text-align: center;
     }
    }
    .spacer-flag {
      width:10px;
    }
  }
}

@keyframes scrollAnimation {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -100%, 0);
  }
}

span.money-icon:before {
  position:absolute;
  content:'';
  width:1px;
  background-color:#C9CBCF;
  height:100%;
  top:0;
  right:45px;
}
span.money-icon {
  margin-left:25px;
}

@media only screen and (max-width: 768px) {
  .domains-container {
    background-color: #FBFBFA;
    height: 88px;
  }
  .main-nav-header {
    height: 88px;
  }
}