footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //box-shadow: 0px -10px 45px -20px rgba(0, 0, 0, 0.75);
}

.text-decoration-underline {
  text-decoration: underline;
}

.privacy-policy {
  a {
    text-decoration: underline;
  }
}
.img-footer{
  text-align: center;
  position: relative;
  overflow: hidden;
  img{
    position: relative;
    bottom: -100px;
  }
  span{
    position: absolute;
    top: 180px;
    margin-left: -40px;
    background-color: #FCEAEF;
    padding: 5px;
    border-radius: 5px;
    img{
      position: absolute;
      top: 10px;
      left: -20px;
    }
  }
}
.footer__acredited {
  padding: 5%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-bottom: 2px solid #f1f1f1;
  img {
    width: 35%;
    margin: auto;
  }
}

.stores {
  width: 100%;
  padding: 10px;
  //border-bottom: 1px solid #eee;
  img {
    width: 50%;
  }
  img:first-child {
    padding-right: 1em;
  }
}

.footer__logo {
  width: 100%;
  padding: 1em 0;
  //border-bottom: 1px solid #eee;
  text-align: center;
  margin: auto;
  img {
    height: 3rem;
  }
}

.footer__desktop {
  display: none;
  .w-180{
    img{
      width: 180px;
    }
    text-align: right;
  }
  text-align: center;
  grid-template-columns: 100%;
  .logo-container{
    img{
      width: 275px;
    }
  }
}

.footer__gameproducers {
  display: grid;
  width: 100%;
  padding: 5%;
  border-bottom: 2px solid #f1f1f1;
  &--gamecards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }
  &--gamecard {
    flex: 0 0 calc(33% - 0.5rem);
    border: 1px solid #cfcfcf;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    text-align: center;
    img {
      width: 80px;
    }
  }
}

.footer__legaldisclaimer {
  width: 100%;
  padding: 3%;
  border-bottom: 2px solid #f1f1f1;
  &--box a svg,
  &--box span svg {
    width: 10%;
    height: 50%;
    margin: 1rem;
    filter: grayscale(100);
  }
  &--box a:first-child svg,
  &--box span:nth-child(2) svg {
    width: 20%;
  }
}

.footer__legaldisclaimer--box {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 991px) {
  .footer__logo{
    img{
      height: 6rem;
    }
  }
}
@media only screen and (max-width: 768px) {
  .footer__logo{
    img{
      height: 6rem;
    }
  }
  footer {
    .stores{
      display: none;
    }
  }
}
@media only screen and (min-width: 768px) {
  .footer__gameproducers--gamecard {
    flex-basis: calc(25% - 0.5rem);
  }
}

@media only screen and (min-width: 1024px) {
  .stores,
  .footer__logo,
  .accordion,
  .footer__acredited {
    display: none;
  }

  .footer__legaldisclaimer {
    &--box a svg,
    &--box span svg {
      margin: 0;
    }
  }

  .footer__legaldisclaimer--box {
    width: 50%;
    height: 50px;
  }

  .footer__gameproducers--gamecard {
    flex-basis: calc(16%);
  }

  .footer__desktop {
    display: grid;
    width: 100%;
    padding: 3% 0;
    align-items: center;
    //grid-template-columns: 65% 20% 15%;
    max-width: 1280px;
  }
  .border-b-1{
    border-bottom: 2px solid #f1f1f1;
    width: 100%;
  }
}

footer a{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  //text-decoration: none;
}

.contact {
  width: 90%;
  margin: 1rem;
}

@media only screen and (max-width: 991px) {
  footer a {
    font-size: 12px;
    line-height: 16px;
  }
}
.banner-footer{
  top: -200px;
  max-width: 1280px;
  margin-bottom: -220px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -302px;
}
.blur-footer{
  box-shadow: 0 -95px 30px #fff;
  position: relative;
  top: -180px;
  background-color: #FBFBFA;
  filter: blur(4px);
  height: 200px;
  width: 100%;
  margin-bottom: -230px;
}
.img-footer-mys{
  right: 50px;
}
.img-footer-dealer{
  left: 100px;
}
.img-footer-mys,
.img-footer-dealer{
  position: absolute;
  bottom: 0;
}
.pos-relative{
  position: relative;
}
.img-banner-footer{
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 400px;
}

.text-banner-footer{
  //font-size: 32px;
  //line-height: 36px;
  //font-weight: 1000;
  //background: linear-gradient(90deg, #E32862 0%, #510098 100%);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  //-webkit-text-stroke: 1.2px #fff;
  //paint-order: stroke fill;
  position: absolute;
  width: 100%;
  top: 40px;
  z-index: 1;
  //letter-spacing: -0.01em;
  //text-shadow: 3px 3px 0 #960F39;
}
.text-jack-footer{
  background-color: #FCEAEF;
  display: inline-block;
  padding: 16px;
  border-radius: 10px;
  letter-spacing: 2px;
  margin-left: 24px;
  img{
    position: absolute;
    margin-left: -37px;
  }
}
.jack-footer{
  text-align: center;
  position: relative;
  bottom: -40px;
  z-index: 1;
  top: -260px;
}
.row-head-img{
  justify-content: center;

  p{
    color: #E32862;
    font-size: 20px;
    font-weight: 1000;
    margin-top: 10px;
  }

  .col-md-4{
    text-align: center;
    max-width: 330px;
  }
}
.font30{
  font-size: 30px !important;
}
.overflow-x-clip{
  overflow-x: clip;
}

@media only screen and (max-width: 1150px) {
  .img-footer-dealer{
    left: 0;
  }

  .img-footer-mys{
    right: -45px;
    overflow-x: hidden;
  }
}
@media only screen and (max-width: 991px) {
  .text-banner-footer{
    bottom: 20px;
    top: auto;
  }
}

@media only screen and (max-width: 767px) {
  .div-head-img{
    max-width: 100%;
    .col-md-4{
      max-width: 50%;
      img{
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .img-footer-mys{
    max-height: 465px;
    right: -110px;
  }
  .img-footer-dealer{
    max-height: 390px;
    left: -30px;
  }
  .img-banner-footer{
    padding: 0;
  }
}
@media only screen and (max-width: 450px) {
  .img-footer-mys{
    max-height: 435px;
    right: -125px;
  }
  .img-footer-dealer{
    max-height: 360px;
    left: -50px;
  }
  .text-banner-footer{
    font-size: 28px;
  }
}

.disable-blur{
  box-shadow: none;
  filter: blur(0);
  margin-top: 280px;
}

@media only screen and (max-width: 767px) {
  .blur-footer{
    top: -165px;
    margin-bottom: -170px;
  }

  .div-head-img{
    max-width: 100%;
    .col-md-4{
      max-width: 50%;
      img{
        width: 100%;
      }
    }
  }
  .blur-footer{
    top: -135px;
    margin-bottom: -170px;
  }
}

